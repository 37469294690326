import React from "react";
import "./FooterStyles.css";
import {
  FaFacebook,
  FaLinkedin,
  FaInstagram
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="right">
          
          <h4>About SLR</h4>
          
          <p>
            Sam Light has been racing karts since the age of 4, starting out on
            the Rye house circuit in Hoddesdon. Sam has now progressed from the
            bambino days through junior rotax to senior rotax where he competes
            in national kart championships.
          </p>
          
          <div className="social">
            <a href="https://www.instagram.com/samlightracing1/?hl=en">
              <FaInstagram size={30} className="highlight" />
            </a>

            <a href="https://www.linkedin.com/in/sam-light-34a268234/">
              <FaLinkedin size={30} className="highlight"/>
            </a>

            <a href="https://www.facebook.com/sam.light.986227">
              <FaFacebook size={30} className="highlight"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
